import { FC } from "react";
import arrow from "./arrow.module.scss";
import classNames from "../../utils/classNames";

export type ArrowDirection = "up" | "down";

export const Arrow: FC<{ direction?: ArrowDirection }> = ({
  direction = "up",
}) => {
  const classes = classNames(
    arrow.arrow,
    direction === "down" ? arrow.arrowExpanded : ""
  );

  return (
    <div className={classes}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.01 11.3345C14.3367 11.0078 14.3367 10.4811 14.01 10.1545L8.46999 4.61448C8.20999 4.35448 7.78999 4.35448 7.52999 4.61448L1.99 10.1545C1.66333 10.4811 1.66333 11.0078 1.99 11.3345C2.31666 11.6611 2.84333 11.6611 3.17 11.3345L8.00333 6.50782L12.8367 11.3411C13.1567 11.6611 13.69 11.6611 14.01 11.3345Z"
          fill="#C5C5CE"
        />
      </svg>
    </div>
  );
};
