import { FC } from "react";
import logo from "./logo.module.scss";

export const Logo: FC = () => {
  return (
    <a href="/" className={logo.logo}>
      <img src="/images/logo.png" alt="Logo" />
    </a>
  );
};
