import { FC } from "react";
import { Section } from "../";
import slider from "./sliderScreen.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import { useTranslation } from "react-i18next";

export const SliderScreen: FC = () => {
  const { t } = useTranslation();
  const slides: number[] = [];
  for (let i = 1; i <= 17; i++) {
    slides.push(i);
  }

  return (
    <Section>
      <div>
        <Swiper
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 2000 }}
          spaceBetween={-10}
          slidesPerView={1.16}
          centeredSlides={true}
          loop={true}
          roundLengths={true}
          pagination={{ clickable: true }}
          breakpoints={{
            1280: {
              spaceBetween: -140,
              slidesPerView: 1.6,
            },
            1920: {
              slidesPerView: 2.44,
              spaceBetween: -210,
            },
          }}
        >
          {slides.map((slide) => (
            <SwiperSlide key={slide} className={slider.slide}>
              <img src={`/images/slides/slide-${slide}.jpg`} alt="Slide" />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={slider.text}>
          <h2>{t("sliderScreen.title")}</h2>
          <p>{t("sliderScreen.description")}</p>
        </div>
      </div>
    </Section>
  );
};
