import { FC } from "react";
import { Section, Container, Wheel, Button } from "../";
import screen from "./offer.module.scss";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

export const Offer: FC = () => {
  const { t } = useTranslation();

  const bets = [
    "number",
    "dozen",
    "highLow",
    "color",
    "final",
    "colorAndHighLow",
    "mirror",
    "sectors",
    "twins",
    "evenOdd",
  ];

  const handleSlider = (swiper: any) => {
    const isDesktop = window.matchMedia("(min-width: 1280px)").matches;
    if (isDesktop) {
      swiper.disable();
    }
  };

  return (
    <Section>
      <Container>
        <div className={screen.wrapper}>
          <div className={screen.leftWrapper} id="leftWrapper">
            <div className={screen.textWrapper}>
              <h2
                className={screen.title}
                dangerouslySetInnerHTML={{ __html: t("offer.title") }}
              />
              <p className={screen.description}>{t("offer.description")}</p>
            </div>
            <div>
              <div className={screen.light} />
              <div className={screen.lightSecond} />
              <Swiper
                modules={[Pagination, Autoplay]}
                pagination={{ clickable: true }}
                slidesPerView="auto"
                onSwiper={handleSlider}
                className={screen.betsSlider}
                spaceBetween={20}
                breakpoints={{
                  1280: {
                    spaceBetween: 0,
                  },
                }}
              >
                {bets.map((bet) => (
                  <SwiperSlide key={bet}>
                    <div className={screen.slideItem}>
                      <div className={screen.slideImage}>
                        <img src={`/images/bets/${bet}.png`} alt="Bet" />
                      </div>
                      <div className={screen.slideText}>
                        <h4>{t(`offer.bets.${bet}.title`)}</h4>
                        <p>{t(`offer.bets.${bet}.description`)}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            <Button>{t("buttons.contact")}</Button>
          </div>
          <Wheel />
        </div>
      </Container>
    </Section>
  );
};
