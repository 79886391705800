import { FC } from "react";
import contactItem from "./contactItem.module.scss";
import { Mail, Skype, Telegram, WhatsApp } from "../Icons";
import classNames from "../../utils/classNames";

interface ContactItemProps {
  item: {
    link: string;
    name: string;
  };
  inButton: boolean;
}

export const ContactItem: FC<ContactItemProps> = ({ item, inButton }) => {
  return (
    <li>
      <a
        className={classNames(
          contactItem.item,
          inButton ? contactItem.inButton : null
        )}
        href={item.link}
        target="_blank"
        rel="noreferrer"
      >
        <div className={contactItem.icon}>
          {item.name === "skype" && <Skype />}
          {item.name === "telegram" && <Telegram />}
          {item.name === "whatsapp" && <WhatsApp />}
          {item.name === "mail" && <Mail />}
        </div>
      </a>
    </li>
  );
};
