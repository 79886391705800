import { FC } from "react";
import { Section, Container, AdvantageItem } from "../";
import screen from "./secondScreen.module.scss";

export const SecondScreen: FC = () => {
  const items = ["jp", "rebetFunction", "choice", "odds"];
  return (
    <Section>
      <Container>
        <ul className={screen.wrapper}>
          {items.map((item) => (
            <AdvantageItem key={item} advantage={item} />
          ))}
        </ul>
      </Container>
    </Section>
  );
};
