import { FC } from "react";
import contacts from "./contacts.module.scss";
import { ContactItem } from "../ContactItem/ContactItem";
import classNames from "../../utils/classNames";

export const Contacts: FC<{ inButton?: boolean }> = ({ inButton = false }) => {
  const items = [
    { id: 0, link: "skype:live:.cid.3d956ddfa66de186?chat", name: "skype" },
    { id: 1, link: "https://t.me/r_3_za", name: "telegram" },
    {
      id: 2,
      link: "https://api.whatsapp.com/send/?phone=447882334096",
      name: "whatsapp",
    },
    { id: 3, link: "mailto:office@ib-games.com", name: "mail" },
  ];

  return (
    <ul className={classNames(contacts.wrapper, contacts.inButton)}>
      {items.map((item) => (
        <ContactItem key={item.id} item={item} inButton={inButton} />
      ))}
    </ul>
  );
};
