import { FC } from "react";
import { Section } from "../Section/Section";
import { Container } from "../Container/Container";
import { useTranslation } from "react-i18next";
import fourth from "./fourthBlock.module.scss";

export const FourthBlock: FC = () => {
  const { t } = useTranslation();
  const items = ["marketingTools", "advancedStatistics", "popularDesign"];

  return (
    <Section>
      <Container>
        <ul className={fourth.wrapper}>
          {items.map((item) => (
            <li key={item} className={fourth.item}>
              <div className={fourth.image}>
                <img src={`/images/fourth/${item}.png`} alt="Banner" />
              </div>
              <div className={fourth.text}>
                <h2>{t(`fourthBlock.${item}.title`)}</h2>
                <p>{t(`fourthBlock.${item}.description`)}</p>
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  );
};
