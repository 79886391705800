import { FC, useEffect, useRef, useState } from "react";
import wheel from "./wheel.module.scss";
import { isInViewport } from "../../utils/isInViewport";
import classNames from "../../utils/classNames";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export const Wheel: FC = () => {
  const wheelRef = useRef<HTMLDivElement>(null);
  const wheelBlock = useRef<HTMLDivElement>(null);
  const [animated, setAnimated] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", handleAnimationStart);
    setTimeout(() => {
      ScrollTrigger.create({
        trigger: "#leftWrapper",
        start: "top top",
        end: "bottom bottom",
        pin: wheelBlock.current,
      });
    }, 1000);

    return () => {
      window.removeEventListener("scroll", handleAnimationStart);
    };
  }, []);

  const handleAnimationStart = () => {
    if (isInViewport(wheelRef.current)) {
      setAnimated(true);
      window.removeEventListener("scroll", handleAnimationStart);
      setInterval(() => setAnimated(false), 11000);
    }
  };

  return (
    <div className={classNames(wheel.wheel)} ref={wheelBlock}>
      <div className={wheel.upperArrow}>
        <img src="/images/wheel/upper-arrow.png" alt="Arrow" />
      </div>
      <div
        ref={wheelRef}
        className={classNames(wheel.centerWheel, animated ? wheel.spin : null)}
      >
        <img src="/images/wheel/wheel.png" alt="Wheel" />
      </div>
      <div className={wheel.centerArrow}>
        <img src="/images/wheel/center-arrow.png" alt="Arrow" />
      </div>
      <div className={wheel.circle}>{!animated && <span>32</span>}</div>
    </div>
  );
};
