import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import ru from "./locales/ru.json";
import es from "./locales/es.json";

i18n.use(initReactI18next).init({
  resources: {
    en,
    ru,
    es,
  },
  lng: localStorage.getItem("locale") || "en",
  fallbackLng: ["en", "es", "ru"],
});
export default i18n;
