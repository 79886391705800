import { ButtonHTMLAttributes, FC, useState } from "react";
import classNames from "../../utils/classNames";
import button from "./button.module.scss";
import { Contacts } from "../";

export const Button: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
  className,
  ...props
}) => {
  const [isSocialsVisible, setIsSocialsVisible] = useState(false);

  const onContactsOpen = () => {
    if (!isSocialsVisible) {
      setIsSocialsVisible(true);
    }
  };

  const onContactsClose = () => {
    if (isSocialsVisible) {
      setIsSocialsVisible(false);
    }
  };

  return (
    <div
      className={classNames(
        button.wrapper,
        isSocialsVisible ? button.social : null
      )}
      onClick={onContactsOpen}
      tabIndex={0}
    >
      <button
        className={classNames(
          button.button,
          className,
          isSocialsVisible ? button.hidden : null
        )}
        {...props}
      >
        <span>{children}</span>
        <span className={button.arrow} />
      </button>
      <div className={button.contacts}>
        <Contacts inButton />
        <div className={button.close} onClick={onContactsClose}>
          ✕
        </div>
      </div>
    </div>
  );
};
