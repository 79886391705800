import { FC } from "react";
import { Section, Container } from "../";
import { useTranslation } from "react-i18next";
import screen from "./bettingArcade.module.scss";
import button from "../Button/button.module.scss";

export const BettingArcade: FC = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <div className={screen.wrapper}>
          <div className={screen.leftWrapper}>
            <div className={screen.textWrapper}>
              <h4
                dangerouslySetInnerHTML={{ __html: t("bettingArcade.title") }}
              />
              <p>{t("bettingArcade.description")}</p>
            </div>
            <div className={button.wrapper}>
              <a
                href="https://clickobet.com/"
                target="_blank"
                rel="noreferrer"
                className={button.button}
              >
                {t("buttons.knowMore")}
                <span className={button.arrow} />
              </a>
            </div>
          </div>
          <div className={screen.image}>
            <div className={screen.light} />
            <img src="/images/betting-arcade.png" alt="Arcade" />
          </div>
        </div>
      </Container>
    </Section>
  );
};
