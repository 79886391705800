import {
  Header,
  FirstScreen,
  SecondScreen,
  SliderScreen,
  Footer,
  FourthBlock,
  SoftwareIncludes,
  BettingArcade,
  Features,
  Offer,
} from "./components";

const App = () => {
  return (
    <>
      <Header />
      <FirstScreen />
      <SecondScreen />
      <SliderScreen />
      <FourthBlock />
      <Offer />
      <SoftwareIncludes />
      <BettingArcade />
      <Features />
      <Footer />
    </>
  );
};

export default App;
