import { FC } from "react";
import { Section, Container, Button } from "../";
import screen from "./softwareIncludes.module.scss";
import { useTranslation } from "react-i18next";

export const SoftwareIncludes: FC = () => {
  const { t } = useTranslation();

  return (
    <Section>
      <Container>
        <div className={screen.wrapper}>
          <div className={screen.image}>
            <div className={screen.light}></div>
            <img src="/images/software-includes.png" alt="Software" />
          </div>
          <div className={screen.rightWrapper}>
            <div className={screen.textWrapper}>
              <h4
                dangerouslySetInnerHTML={{
                  __html: t("softwareIncludes.title"),
                }}
              />
              <p>{t("softwareIncludes.description")}</p>
              <ul>
                <li>{t("softwareIncludes.list.item1")}</li>
                <li>{t("softwareIncludes.list.item2")}</li>
                <li>{t("softwareIncludes.list.item3")}</li>
              </ul>
            </div>
            <Button>{t("buttons.contact")}</Button>
          </div>
        </div>
      </Container>
    </Section>
  );
};
