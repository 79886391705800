import { FC } from "react";
import { Section, Container } from "../";
import screen from "./features.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "../../utils/classNames";

export const Features: FC = () => {
  const { t } = useTranslation();
  const items = ["start", "functionality", "math"];

  return (
    <Section>
      <Container>
        <ul className={screen.wrapper}>
          {items.map((item) => (
            <li key={item} className={screen.item}>
              <div className={classNames(screen.image, screen[item])}>
                <img src={`/images/features/${item}.png`} alt="" />
              </div>
              <div className={screen.textContent}>
                <h6>{t(`features.${item}.title`)}</h6>
                <ul>
                  <li>{t(`features.${item}.list-1`)}</li>
                  <li>{t(`features.${item}.list-2`)}</li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
      </Container>
    </Section>
  );
};
