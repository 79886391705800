import { FC, HTMLAttributes } from "react";
import classNames from "../../utils/classNames";
import section from "./section.module.scss";

export const Section: FC<HTMLAttributes<HTMLElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <section className={classNames(section.section, className)} {...props}>
      {children}
    </section>
  );
};
