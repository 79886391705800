import { FC } from "react";
import screen from "./firstScreen.module.scss";
import { Section, Container, Button } from "../";
import { useTranslation } from "react-i18next";

export const FirstScreen: FC = () => {
  const { t } = useTranslation();

  return (
    <Section className={screen.section}>
      <Container>
        <div className={screen.wrapper}>
          <div className={screen.textWrapper}>
            <div className={screen.textBlockBg}>
              <img src="/images/first-screen-bg.png" alt="Gold" />
            </div>
            <div>
              <h2 className={screen.title}>{t("firstScreen.title")}</h2>
              <p className={screen.description}>
                {t("firstScreen.description")}
              </p>
            </div>
            <Button>{t("buttons.contact")}</Button>
          </div>
          <div className={screen.bannerContainer}>
            <div className={screen.lightEllipse} />
            <div className={screen.banner}>
              <div className={screen.tvLight}>
                <img src="/images/tv-light.png" alt="Light" />
              </div>
              <div className={screen.confetti}>
                <picture>
                  <source
                    srcSet="/images/banner-confetti.png"
                    media="(min-width: 1280px)"
                  />
                  <img src="/images/banner-confetti-mob.png" alt="Confetti" />
                </picture>
              </div>
              <picture>
                <img src="/images/tv.png" alt="Banner" />
              </picture>
              <div className={screen.video}>
                <video autoPlay loop muted playsInline preload="true">
                  <source src="/videos/WW.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};
