import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Arrow } from "../";
import langPicker from "./langPicker.module.scss";
import classNames from "../../utils/classNames";

export const LangPicker: FC<{ openDirection?: "top" | "bottom" }> = ({
  openDirection = "bottom",
}) => {
  const { i18n } = useTranslation();
  const [locSwitchActive, setLocSwitchActive] = useState(false);

  const dropdownClassName = classNames(
    langPicker.dropdown,
    openDirection === "bottom"
      ? langPicker.dropdownBottom
      : langPicker.dropdownTop
  );

  const onLangSelectorClick = () => {
    setLocSwitchActive((prevState) => !prevState);
  };

  const onChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("locale", lang);
    setLocSwitchActive(false);
  };

  return (
    <div className={langPicker.wrapper}>
      <div
        className={langPicker.current}
        onClick={onLangSelectorClick}
        onBlur={() => setLocSwitchActive(false)}
        tabIndex={0}
      >
        <div className={langPicker.flag}>
          <img src={`/images/flags/${i18n.language}.jpg`} alt="Flag" />
        </div>
        <div className={langPicker.name}>{i18n.language}</div>
        <Arrow direction={locSwitchActive ? "up" : "down"} />
      </div>
      {locSwitchActive && (
        <ul className={dropdownClassName}>
          {i18n.languages.map((lang) =>
            lang !== i18n.language ? (
              <li
                key={lang}
                onMouseDown={() => onChangeLanguage(lang)}
                className={langPicker.dropdownItem}
              >
                <div className={langPicker.flag}>
                  <img src={`/images/flags/${lang}.jpg`} alt="Flag" />
                </div>
                <span className={langPicker.name}>{lang}</span>
              </li>
            ) : null
          )}
        </ul>
      )}
    </div>
  );
};
