import { FC } from "react";
import item from "./advantageItem.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "../../utils/classNames";

export const AdvantageItem: FC<{ advantage: string }> = ({ advantage }) => {
  const { t } = useTranslation();
  return (
    <li className={item.item}>
      <div className={classNames(item.image, item[advantage])}>
        <img src={`/images/advantages/${advantage}.png`} alt="" />
      </div>
      <div className={item.text}>
        <h4 className={item.title}>{t(`secondScreen.${advantage}.title`)}</h4>
        <p className={item.description}>
          {t(`secondScreen.${advantage}.description`)}
        </p>
      </div>
    </li>
  );
};
