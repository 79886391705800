import { FC } from "react";
import { Container, Logo, LangPicker, Contacts } from "../";
import footer from "./footer.module.scss";
import { useTranslation } from "react-i18next";

export const Footer: FC = () => {
  const { t } = useTranslation();

  return (
    <footer className={footer.footer}>
      <Container>
        <div className={footer.wrapper}>
          <Logo />
          <div className={footer.contactLang}>
            <div className={footer.contacts}>
              <h4 className={footer.contactHeader}>{t("footer.contact")}</h4>
              <Contacts />
            </div>
            <LangPicker openDirection="top" />
          </div>
          <div className={footer.copyright}>
            <span>&#169;</span>
            {` ${new Date().getFullYear()}`}
          </div>
        </div>
      </Container>
    </footer>
  );
};
