import { FC } from "react";

export const WhatsApp: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 13.8896 2.52505 15.6594 3.43756 17.1683L2.54581 20.2002C2.32023 20.9672 3.03284 21.6798 3.79975 21.4542L6.83171 20.5624C8.34058 21.475 10.1104 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM9.73821 14.2627C11.7607 16.2852 13.692 16.5518 14.3739 16.5769C15.4111 16.6151 16.421 15.823 16.8147 14.9042C16.9112 14.6792 16.8871 14.4085 16.7255 14.2014C16.1782 13.5005 15.4373 12.9983 14.7134 12.4984C14.4006 12.282 13.9705 12.349 13.7401 12.6555L13.1394 13.5706C13.0727 13.6721 12.9402 13.707 12.8348 13.6467C12.4283 13.4143 11.8356 13.018 11.4092 12.5916C10.9833 12.1657 10.6111 11.5998 10.4022 11.2195C10.3473 11.1195 10.3777 10.996 10.4692 10.928L11.3927 10.2422C11.6681 10.0038 11.7165 9.59887 11.5138 9.30228C11.065 8.64569 10.5422 7.8112 9.7855 7.25926C9.57883 7.1085 9.3174 7.09158 9.10155 7.18408C8.1817 7.5783 7.38574 8.58789 7.42398 9.62695C7.44908 10.3089 7.71572 12.2402 9.73821 14.2627Z"
        fill="#0B0C1E"
      />
    </svg>
  );
};
