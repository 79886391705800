import { FC } from "react";
import { Logo, Container, LangPicker } from "../";
import header from "./header.module.scss";

export const Header: FC = () => {
  return (
    <header>
      <Container>
        <div className={header.wrapper}>
          <Logo />
          <LangPicker />
        </div>
      </Container>
    </header>
  );
};
