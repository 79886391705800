import { FC } from "react";

export const Telegram: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7773 4.42984C20.8652 3.97177 22.0315 4.8917 21.8394 6.05639L19.5705 19.8131C19.3517 21.1395 17.8949 21.9006 16.678 21.2396C15.6597 20.6865 14.1489 19.8352 12.7873 18.9455C12.1074 18.5012 10.0255 17.0766 10.2814 16.0625C10.5002 15.1954 14.0001 11.9375 16.0001 10C16.7857 9.23893 16.4279 8.79926 15.5001 9.5C13.1985 11.2383 9.50332 13.8812 8.28136 14.625C7.20323 15.2812 6.64031 15.3932 5.96886 15.2812C4.74273 15.0769 3.60596 14.7605 2.67788 14.3758C1.42351 13.8558 1.48461 12.132 2.67703 11.63L19.7773 4.42984Z"
        fill="#0B0C1E"
      />
    </svg>
  );
};
